// originally authored by Nick Pettit - https://github.com/nickpettit/glide

@include keyframes(pulse) {
	  0% {@include transform(scale(1));}
   50% {@include transform(scale(1.1));}
  100% {@include transform(scale(1));}
}

@mixin pulse($count: $countDefault, $duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(pulse);
  @include count($count);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
