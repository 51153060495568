@include keyframes(lightSpeedOut) {
  0% {
    opacity: 1;
    @include transform(translateX(0%) skewX(0deg));
  }
  100% {
    opacity: 0;
    @include transform(translateX(100%) skewX(-30deg));
  }
}

@mixin lightSpeedOut($count: $countDefault, $duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(lightSpeedOut);
  @include count($count);
  @include function(ease-in);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
