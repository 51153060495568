@include keyframes(lightSpeedIn) {
	0% {
  	opacity: 0;
  	@include transform(translateX(100%) skewX(-30deg));
  }
	60% {
  	opacity: 1;
  	@include transform(translateX(-20%) skewX(30deg));
  }
	80% {
  	opacity: 1;
  	@include transform(translateX(0%) skewX(-15deg));
  }
	100% {
  	opacity: 1;
  	@include transform(translateX(0%) skewX(0deg));
  }
}

@mixin lightSpeedIn($count: $countDefault, $duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(lightSpeedIn);
  @include count($count);
	@include function(ease-out);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
