// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
    /** background **/
    --ion-background-color: #fafafa;
    /** primary **/
    --ion-color-primary: #0971CE;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #053967;
    --ion-color-primary-tint: #6BAAE2;

    /** secondary **/
    --ion-color-secondary: #0cd1e8;
    --ion-color-secondary-rgb: 12, 209, 232;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #0971CE;
    --ion-color-secondary-tint: #448cc3;

    /** tertiary **/
    --ion-color-tertiary: #7044ff;
    --ion-color-tertiary-rgb: 112, 68, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #633ce0;
    --ion-color-tertiary-tint: #7e57ff;

    /** success **/
    --ion-color-success: #E61196;
    --ion-color-success-rgb: 16, 220, 96;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #AD0D71;
    --ion-color-success-tint: #0971CE;

    /** warning **/
    --ion-color-warning: #ffa600;
    --ion-color-warning-rgb: 255, 206, 0;
    --ion-color-warning-contrast: #ffffff;
    --ion-color-warning-contrast-rgb: 255, 255, 255;
    --ion-color-warning-shade: #e08600;
    --ion-color-warning-tint: #ffaf1a;

    /** danger **/
    --ion-color-danger: #f04141;
    --ion-color-danger-rgb: 245, 61, 61;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #d33939;
    --ion-color-danger-tint: #f25454;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 34, 34;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 244, 244;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    /** transparent background **/
    --ion-color-transparent: transparent;

    /** font size **/
    --main-sm-text: 12px;
    --main-mid-text: 16px;
    --main-lg-text: 20px;
    --main-xlg-text: 24px;

    /** padding **/
    --main-padding: 16px;
    --main-padding-sm: 8px;
    --main-padding-lg: 32px;

     /** margin **/
     --main-margin: 16px;

      /** button radius **/
      --main-key-btn-radius: 8px;
      --main-sm-btn-radius: 4px;
      --main-round-btn-radius: 100px;

      /** solid button style **/
      --main-key-btn-solid: var(--ion-color-primary);
      --main-key-btn-solid-text: var(--ion-color-primary-contrast);

      /** solid button style **/
      --main-key-btn-outline: var(--ion-color-primary);
      --main-key-btn-outline-text: var(--ion-color-primary);

      /** key button shadow **/
      --main-card-shadow: 0 4px 16px rgba(0,0,0,.12);
      --main-key-btn-shadow: 0px 0px 16px rgba(0,0,0,.22);

      --image-ratio:100%;
  //     --pay-gradient-start:#ec8b00;
  //     --pay-gradient-end:#ecad00;
      --pay-text:#fff;

      --grid-items-per-row: 2;

      --toolbar-bg:#0971CE;
      --back-btn: #ffffff;
      --chip-badge: #ffffff;
      --chip-num: #000000;
      --content-bg: #f1f1f1;
      --itemlist-bg:  #ffffff;
      --startext-color: #ffffff;
      --startbtn-bg: #0971CE;
      --startbtn-active: #0971CE;
      --startbtn-shadow: 0px 0px 16px rgba(0,0,0,.22);
      --table-num: #000000;
      --tablenum-shadow: none;
      --display-store: block;
      --ext-txt: #494b9e;

  }

  $colors: (
          // primary:    #4587f0,
          // secondary:  #F06C06,
          // danger:     #F44812,
          // opbrand: #EBAB44,
          // light:      #e2e2e2,
          // dark:       #222,
          // button:      #2ab3b9,
          // contrast: #fff,
          // success:     #22AE73,
          // light-text : #939393,
          // text-color: #000,
          // background-color: #f2f0f0,
          // toolbar-background: #222,
          // brand: #2a2928,
          // brand-sub:  #2a2928,
          // light-grey: #b3b3b3,
  );

  // App Theme
  // --------------------------------------------------
  // Ionic apps can have different themes applied, which can
  // then be future customized. This import comes last
  // so that the above variables are used and Ionic's
  // default are overridden.

  //@import "ionic.theme.default";



  // Ionicons
  // --------------------------------------------------
  // The premium icon font for Ionic. For more info, please see:
  // http://ionicframework.com/docs/v2/ionicons/

  $ionicons-font-path: "../../assets/fonts";
  //@import "ionicons";


  $item-md-detail-push-show: true;

  // @import "lpStyle";
  // @import "shakeShackStyle";
  // @import "yoshinoyaStyle";
  @import "nandos";
