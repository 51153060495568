@include keyframes(hinge) {
  0% {
    animation-timing-function: ease-in-out;
    @include transform(rotate(0));
    @include transform-origin(top left);
  }
  20%, 60% {
    animation-timing-function: ease-in-out;
    @include transform(rotate(80deg));
    @include transform-origin(top left);
  }
  40% {
    animation-timing-function: ease-in-out;
    @include transform(rotate(60deg));
    @include transform-origin(top left);
  }
  80% {
    opacity: 1;
    animation-timing-function: ease-in-out;
    @include transform(rotate(60deg) translateY(0));
    @include transform-origin(top left);
  }
  100% {
    opacity: 0;
    @include transform(translateY(700px));
  }
}

@mixin hinge($count: $countDefault, $duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(hinge);
  @include count($count);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
