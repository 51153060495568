#cId5767590991364096{
    page-direct-byod-splash{
        ion-footer{
            .ion-color-primary{
                --ion-color-primary: #cb333b;
            }
            .start-btn{
                --background:#cb333b;
            }
        }
    }
    item-grid-scroll-page .itemPrice-text{
        // color:#111;
    }

    cart-scroll-page .price-text.itemPrice-text.item-price-single{
        flex-grow: 1;
        text-align: right; 
    }

    category-list-page {
        span.qty-text{
            width: 1.1rem;
            font-size: 0.95rem;
        }
    
        .price-text.itemPrice-text{
            letter-spacing: -0.05em;
            font-size: 0.95rem;
        }
    }

    app-grid-layout-item-double{
        div.item-price{
            display:flex;
            align-items: flex-end;
            flex-grow: 1;
        }
    }


}
