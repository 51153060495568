@include keyframes(shake) {
  0%, 100% {@include transform(translateX(0));}
	10%, 30%, 50%, 70%, 90% {@include transform(translateX(-10px));}
	20%, 40%, 60%, 80% {@include transform(translateX(10px));}
}

@mixin shake($count: $countDefault, $duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
	@include animation-name(shake);
	@include count($count);
	@include duration($duration);
	@include delay($delay);
	@include function($function);
	@include fill-mode($fill);
	@include visibility($visibility);
}
