ion-toast.add-item-toast{
    --background: #ffedc0;
    --border-color: #ffedc0;
    --border-style: solid;
    --boder-width: 1px;
    height: calc(100% - 55px) !important;
}

//// toast controller ////
ion-toast.toast-box {
    height: calc(100% - 45px) !important;
  }

  
  // toast style//
  .normal-toast,
  .toast-box {
    .toast-wrapper {
      background: #ffedc0;
      box-shadow: 0 0px 12px rgba(0, 0, 0, 0.1), 0 0px 16px rgba(0, 0, 0, 0.16);
  
      .toast-message {
        color: #666;
        display: inline-flex;
        align-items: center;
        padding: .8rem 1.6rem;
  
        &:before {
          color: var(--ion-color-success);
          font-size: 2.4rem;
          padding-right: 8px;
          display: inline-block;
          font-family: "Ionicons";
          content: "\f14a";
          /* content:url('/assets/images/completed_s.png'); */
        }
      }
    }
  }